<template>
  <div class="pagess">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="pagess-bach"
      @click.stop="back()"
    />
    <div class="userAgreement">
      <div class="userAgreement-title">《泡泡计划》用户服务协议</div>
      <div class="userAgreement-text">
        《泡泡计划用户服务协议》(以下简称“本协议”)是由您与泡泡计划(以下称“我们”)所订立的有效合约。请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有任何疑问的，请勿进行下一步操作。
      </div>
      <div class="userAgreement-bole">特别提醒</div>
      <div class="userAgreement-text">
        您需审慎阅读、充分理解本协议的各个条款，尤其是以粗体并下划线标示的条款，包括第三条的数字文化艺术品购买和转赠规则、第四条的平台服务说明及使用规范、第五条用户违约及处理措施、第八条免责事项、第十条的法律适用和争议解决以及附件一的《违规用户处罚规则》等可能影响您权利和责任的相应条款。您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，知晓并理解本协议所有条款的含义且愿意承担相应的法律后果。本协议视为已于您在线点击确认或以其他方式签署本协议或您完成注册程序之日有效签署，对各方成立并生效。我们关注且重视未成年人权益的保障。若您未满十八周岁，请在监护人的陪同下阅读和判断是否同意本协议。您点击确认或其他类似按钮并继续使用本服务即视为您已经取得监护人的必要同意。
      </div>
      <div class="userAgreement-bole">一、定义</div>
      <div class="userAgreement-text">
        1.1数字文化艺术品平台或平台:指我们开发运营的数字文化艺术品的发行、信息展示和交易的平台，包括泡泡计划平台、及其后续我们不时开发的其他
        App、小程序或网页等应用软件。
      </div>
      <div class="userAgreement-text">
        1.2数字文化艺术品:指使用区块链技术进行唯一标识的经数字化的特定虚拟作品、艺术品和商品，包括但不限于数字图片、音乐、视频、3D模型等各种形式的虚拟数字文化艺术品。
      </div>
      <div class="userAgreement-text">
        1.3授权方:指拥有数字文化艺术品相关IP授权的实体或个人
      </div>
      <div class="userAgreement-text">
        1.4发行方:在平台发行数字文化艺术品并向您销售和提供数字文化艺术品的实体或个人。
      </div>
      <div class="userAgreement-text">
        1.5服务:指我们以及我们的关联方和合作方基于互联网和区块链，以包含数字文化艺术品平台在内的各种形态(包括未来技术发展出现的新的服务形态)向您提供的各项服务，包括但不限于以下服务:(i)数字文化艺术品的展示、浏览、购买、转赠、交易争议处理、订单管理等信息技术服务;(ii)数字文化艺术品的应用场景相关的应用服务;以及(iii)将数字文化艺术品在区块链进行存证、管理、簿记及交收等存管服务。
      </div>
      <div class="userAgreement-text">
        1.6平台规则:指已在或后续将在平台发布的各类规则、规范公告、说明、解读、声明、指引或其他类似内容，具体以平台实际展示为准。
      </div>
      <div class="userAgreement-text">
        1.7应用场景:指平台之外的提供数字文化艺术品信息展示、交互和其他应用活动的各类
        App 、小程序和网页等应用软件。
      </div>
      <div class="userAgreement-bole">二、账户注册、管理和注销</div>
      <div class="userAgreement-text">
        2.1在您通过平台注册或使用本服务之前，您应确保您具备与使用本服务及购买、转赠数字文化艺术品等相适应的民事行为能力，并有能力承担对应的法律后果和法律责任。如果您是未成年人，您在平台购买、转赠数字文化艺术品或进行其他行为，均视为您已经获得了您监护人的同意。
      </div>
      <div class="userAgreement-text">
        2.2账户注册:您应当按照平台页面的提示直接提供您的手机号，开立和注册您的平台账户。当您成功完成注册程序后，您即取得平台账户并成为平台用户。在平台一个手机号仅能注册和绑定一个账户。为保障您的账户安全，您同意按照平台的要求及相关法律法规规定完成账户的实名认证。
      </div>
      <div class="userAgreement-text">
        2.3账户登入:您可通过您的账户名称(即手机号)和手机验证码、或其他平台允许的方式登录平台并使用本服务。
      </div>
      <div class="userAgreement-text">
        2.4账户管理:您的账户仅限于您本人使用。除法律法规或平台规则另有规定，您不得将您的账户以任何方式进行转让、出借或出售。请您务必妥善保管账户登录名、短信校验码等信息。在您的账户登录状态下进行的所有操作、发出的所有指令均视为您本人做出。如果因您自身的原因造成您的账户被冒用、盗用或非法使用的，我们不承担由此造成的风险和您的损失。
      </div>
      <div class="userAgreement-text">
        2.5账户的限权:我们有权依据本协议约定或者法律法规的规定对您的账户进行限权或关闭。在限权或关闭账户之前，我们将通知您对账户内的数字文化艺术品进行处置。
      </div>
      <div class="userAgreement-text">
        2.6账户注销:您可以在泡泡计划平台里申请注销您的账户。在申请注销账户之前，您需对账户内的数字文化艺术品进行处置，包括在符合法律法规和交易平台规则的情况下将数字文化艺术品转移至另一账户。如果在您发起注销账户申请时持有数字文化艺术品，需联系泡泡计划官方进行安全认证后，方可进行注销操作。请您注意账户一旦成功注销将不可恢复，即使您在注销后以同一手机号再次在平台注册账号，该账户也将视为新的用户账号，您在原注销账户内的数字文化艺术品将无法被召回。
      </div>
      <div class="userAgreement-bole">
        三、数字文化艺术品购买、转卖和转赠规则
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.1 数字文化艺术品的购买:
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.1.1
        您通过数字文化艺术品平台购买或兑换数字文化艺术品应当是已年满十八周岁的中国大陆居民，并且已经通过平台的实名认证流程。
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.2数字文化艺术品转卖
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.2.1在平台规则或平台功能允许的情况下，您可以将所持有的数字资产转售给平台的其他用户。您理解并同意，一旦完成转售操作将无法撤销，且转售成功后，与数字资产相关的权利将同步且毫无保留地转移至受让人。
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.2.2 您通过平台转售数字资产，应当满足以下必要的条件
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        (1)您与受让人均为中国大陆居民身份且年满18周岁;
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        (2)您与受让人均已通过平台的实名认证。
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.3 数字文化艺术品的转赠
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.3.1在平台规则或平台功能允许的情况下，您可以将所持有的数字文化艺术品转赠给平台的其他用户。您理解并同意，-发起转赠操作将无法撤销，且转赠成功后，与数字文化艺术品相关的权利将同步且毫无保留地转移至受赠人。
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.3.2 您通过平台转赠数字文化艺术品，应当满足以下必要的条件:
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        (1)您与受赠人均为中国大陆居民身份且已年满十八周岁;
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        (2)您与受赠人均已通过平台的实名认证;
      </div>
      <div class="userAgreement-text" style="font-weight: 800">
        3.4
        您理解并确认，我们有权依据国家法律法规、监管政策，平台规则或业务需要对上述购买、转卖和转赠条件进行补充或修改。您一旦点击页面“确认”按钮确认本协议或规则，或者发起购买或转卖、转赠申请，即表示您与受让/赠人承诺符合购买转卖/转赠条件并同意受到本协议及平台规则的约束。
      </div>
      <div class="userAgreement-bole">四、平台服务说明及使用规范</div>
      <div class="userAgreement-text">4.1 平台服务说明:</div>
      <div class="userAgreement-text">
        4.1.1您有权在平台上享受数字文化艺术品的浏览、购买、分享、转赠、争议处理、订单管理以及数字文化艺术品的访问欣赏、炫耀、信息查看等信息技术服务，部分数字文化艺术品还可通过平台合作的应用场景进行使用，具体服务内容和功能以平台页面向您实际展示的为准。
      </div>
      <div class="userAgreement-text">
        4.1.2您可以通过平台浏览不同数字文化艺术品及其相关信息。当您下单购买前，请您务必仔细确认所购买数字文化艺术品的名称、介绍、授权方、价格、数量及/或其他限制性要求等重要事项，并对是否购买数字文化艺术品做出独立决策和判断。一旦您提交订单，请在规定时限内完成支付。您支付成功后即视为您与发行方之间就已支付价款部分的订单建立了合同关系。特别提醒本平台售出的数字文化艺术品均为虚拟数字商品，而非实物，且因为区块链技术的特性，本平台售出的数字文化艺术品不支持七天无理由退换。
      </div>
      <div class="userAgreement-text">
        4.1.3基于区块链技术的特性，数字文化艺术品具有加密和不可篡改的特点。一旦上链，数字文化艺术品元数据中的作者、创作时间和上链时间、购买者等信息，在链上无法篡改。您购买相关数字文化艺术品后，您的购买者信息将记录在数字文化艺术品的元数据中，作为您持有该数字文化艺术品的权利凭证。
      </div>
      <div class="userAgreement-text">
        4.1.4数字文化艺术品的图文展示、介绍、创作者等相关信息均由发行方提供，所有数字文化艺术品的内容和版权由发行方单独承担责任。您应当对通过平台接触到的内容及其准确性、完整性和有效性自行审慎判断并承担由此引起的相应风险。除我们存在故意或重大过失外，我们不对您因前述风险而导致的失或损害承担责任。
      </div>
      <div class="userAgreement-text">
        4.1.5为保障您和发行方的交易和资金安全，您同意以杉德支付作为您购买数字文化艺术品的支付工具。
      </div>
      <div class="userAgreement-text">
        4.1.6您理解并同意，数字文化艺术品的版权由授权方、发行方或原作创作者拥有。除另行取得版权权利人书面同意外，您不得将数字文化艺术品用于任何商业用途。除平台规则或平台功能允许外，您获得的数字文化艺术品应仅用于自身收藏、学习、研究、欣赏和展示目的，不得用于任何其他目的。
      </div>
      <div class="userAgreement-text">
        4.1.7我们严格禁止您利用数字文化艺术品进行售卖、炒作、场外交易、欺诈或以任何其他非法方式进行使用。我们保留随时根据业务发展和法律法规政策变化进行调整的权利。您认可并同意，为了保障交易安全、有序、合法以及履行我们反洗钱的义务，我们有权对您的区块链地址进行安全查询。如发现存在售卖、炒作、欺诈等异常行为，或您存在违反法律法规或本议约定等情形，我们有权对您采取本协议第五条约定的必要措施。
      </div>
      <div class="userAgreement-text">4.2 平台服务使用规范:</div>
      <div class="userAgreement-text">
        4.2.1您承诺将始终遵守本协议以及与数字文化艺术品相关的交易订单、平台规则以及我们不时更新且在平台公示的平台规则，包括但不限于本协议附件一规定的《违规用户处罚规则》等，并遵守其他第三方服务提供商(如有)与您达成的相关议或规则。
      </div>
      <div class="userAgreement-text">
        4.2.2
        您声明并保证，您对您在使用本服务及购买数字文化艺术品的过程中所提供、发布的任何信息拥有完整、合法、有效的权利，并且符合相关法律法规及本协议的要求，不存在任何违反法律法规、国家政策以及其他违背社会公共利益、公共道德或妨害平台正常运行、侵犯他人合法权益的信息或行为。您同意我们有权对您提供或发布的信息内容进行审查，如发现违规或不适宜在平台展示的，我们有权进行删除或屏蔽处理。
      </div>
      <div class="userAgreement-text">
        4.2.3
        您应对通过本服务了解、接收或接触到的包括但不限于授权方、发行方或其他用户在内的任何个人信息和资料予以充分尊重。您不得擅自搜集、复制、存储、传播或以其他任何商业目的使用前述个人信息和资料，不得擅自使用任何第三方软件、插件、外挂、系统等查看、获取本服务中所包含的我们及我们的关联方、合作方、发行方或授权方、其他用户的任何相关信息、数据等内容。
      </div>
      <div class="userAgreement-text">
        4.2.4
        您理解，我们提供本服务有赖于平台系统的准确运行及作。若出现系统差错、故障或其他原因引发了展示错误、您获得不当获利等情形的，您同意我们可以自行或通过我们的关联方、合作方采取更正差错、暂停服务、划扣款项等适当纠正施。
      </div>
      <div class="userAgreement-text">
        4.2.5
        您在使用本服务或数字文化艺术品交易过程中与发行方应用场景提供方或其他用户等第三方产生的任何争议，应由您自行和发行方或其他第三方协商解决，我们将为您提供必要的支持和协助。如果您申请我们为您提供争议调处服务，或您我们进行任何投诉、举报或通知，您需要向我们提供相应的凭证以初步证明您的主张。
      </div>
      <div class="userAgreement-bole">五、用户违约及处理措施</div>
      <div class="userAgreement-text">
        5.1您理解并同意，为了维护交易秩序和交易安全，如我们发现或合理判定您存在违法违规或违反本协议约定情形的，我们有权采取救济措施，包括但不限于:
      </div>
      <div class="userAgreement-text">(1)要求您限期纠正违规或违约行为;</div>
      <div class="userAgreement-text">(2)主动执行关闭相关交易订单;</div>
      <div class="userAgreement-text">
        (3)屏蔽或限制您访问、使用数字文化艺术品或限制您持有的数字文化艺术品的某些功能;
      </div>
      <div class="userAgreement-text">(4)对违法违规信息进行删除或屏蔽;</div>
      <div class="userAgreement-text">(5)暂停或终止向您提供部分或全部服务;</div>
      <div class="userAgreement-text">
        (6)采取本协议附件一《违规用户处罚规则》规定的相关附件一《违规用户处罚规则》规定的相关处罚措施;以及其他合理、合法的措施。
      </div>
      <div class="userAgreement-text">
        5.2如您的行为使我们及/或我们的关联方和合作方等遭受损失的，我们有权要求您赔偿我们的全部损失，包括商誉、支付的赔偿金、律师费、诉讼费等。
      </div>
      <div class="userAgreement-bole">六、 平台许可</div>
      <div class="userAgreement-text">
        6.1我们授予您一项仅限于个人非商业目的、非排他性、可撤销、不可转让、不可分许可的有限的普通许可，您可以在本议有效期内，按照本协议及平台规则限定的范围和方式使用泡泡计划
        平台及相关平台服务(以下简称“本平台”)。未经我们另行书面许可，你不得为商业目的下载、安装、登录和使用本平台。本协议未明示授权的其他一切权利仍由我们或相关权利方保留。
      </div>
      <div class="userAgreement-text">
        6.2我们会不断丰富您使用本平台的终端、内容和形式。您理解由于平台适配及终端限制，我们可能开发不同的平台版本。您应当选择与您的终端、系统等相匹配的平台版本，否则由此造成的本平台无法正常运行或您的终端、系统及其软硬件功能损害的，我们不承担责任。
      </div>
      <div class="userAgreement-text">
        6.3为了改善用户体验、完善服务内容、以及提高平台安全性等目的，我们有权对本平台及相关平台服务、功能、界面等进行更新、修改，包括开发新的功能、删除旧功能等，该等变更不视为我们对本协议的履行存在任何瑕疵，您同意不会因此追究我们的责任。您理解在本平台版本更新后，我们不保证旧版本的平台及其服务、功能等可以继续正常使用，请您随时关注并将本平台更新至最新版本。
      </div>
      <div class="userAgreement-text">
        6.4您应当从本平台的官方网站、官方应用分发平台及/或我们授权的其他第三方网站、应用分发平台等渠道获取本平台。如果您从非官方或未经我们授权的第三方获取本平台或与本平台名称相同的安装程序，我们无法保证该平台能够正常使用和运行，由此产生的任何损害或损失将由您自行承担。
      </div>
      <div class="userAgreement-text">
        6.5您仅可通过本人人工操作的方式使用本平台及相关平台服务，不得自行或允许第三方通过非官方程序、机器、脚本、爬虫或其他自动化的方式使用本平台及相关平台服务或获取该等平台、内容和服务数据。七、用户信息的保护及授权
        我们非常重视对您个人信息的保护，您同意我们依据本协议及《隐私政策》向您收集为您提供本服务所需要的信息并进行合理使用、处理、存储和共享。我们将遵循合法、正当、必要和诚信、公开、透明的原则，依据适用法律法规及平台隐私政策的要求严格保护您的个人信息，请您在使用本服务前仔细阅读《隐私政策》，如有任何疑问请随时联系我们。
      </div>
      <div class="userAgreement-bole">七、用户信息的保护及授权</div>
      <div class="userAgreement-text">
        我们非常重视对您个人信息的保护，您同意我们依据本协议及《隐私政策》向您收集为您提供本服务所需要的信息并进行合理使用、处理、存储和共享。我们将遵循合法、正当、必要和诚信、公开、透明的原则，依据适用法律法规及平台隐私政策的要求严格保护您的个人信息，请您在使用本服务前仔细阅读《隐私政策》，如有任何疑问请随时联系我们。
      </div>
      <div class="userAgreement-bole">八、免责事项</div>
      <div class="userAgreement-text">
        8.1我们将努力保障您的合法权益。请您理解，在您购买数字文化艺术品或使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议项下约定或您无法完成数字文化艺术品交易的，我们将努力在第一时间与相关单位配合并及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任:
      </div>
      <div class="userAgreement-text">
        (1)在与本服务相关的系统停机维护或升级期间;
      </div>
      <div class="userAgreement-text">
        (2)因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力因，
      </div>
      <div class="userAgreement-text">
        (3)您操作不当或通过非我们授权或认可的方式使用本服务
      </div>
      <div class="userAgreement-text">
        (4)因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。
      </div>
      <div class="userAgreement-text">
        8.2您理解并同意我们仅向您提供与数字文化艺术品交易相关的信息技术服务及其他辅助服务。除法律法规另有明确规定或本协议另有约定外，我们不成为您与发行方或第三方之间任何交易或业务的参与方，不对所涉数字文化艺术品及/或第三方服务的质量、安全性及其合法性、真实性、准确性作出任何明示或暗示的担保。
      </div>
      <div class="userAgreement-text">
        8.3您理解并确认，我们仅以“现状”向您提供本服务。您在确认接受本协议或使用本服务前已充分了解数字文化艺术品平台的功能和服务特性并同意我们无须就平台瑕疵、功能不足或任何需改进事项对您承担任何除法律有明确规定外的责任。
      </div>
      <div class="userAgreement-text">
        8.4您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。
      </div>
      <div class="userAgreement-text">
        8.5为便于向您提供服务，我们可能在平台设置相应的外部链接。除法律另有明确规定外我们不对外部链接的准确性、有效性、安全性和完整性作出任何承诺或保证。同时，对于该等外部链接指向的不由我们实际控制的任何网页或客户端上的内容，您同意我们将不承担相关责任。
      </div>
      <div class="userAgreement-text">
        8.6本协议项下涉及的所有数字文化艺术品，由发行方保证对字文化艺术品享有作为权利人完整、合法、有效的权利或已取得权利人的合法授权，保证有权开展数字文化艺术品生成、发行、转赠等场景的应用活动，并保证其开展数字文化艺术品业务不违反任何法律法规或侵犯第三方的合法权益。如因数字文化艺术品发行方或原创作者侵权、发布危害国家利益、违反公序良俗以及其他可能产生严重负面舆情的言论或行为，或者数字文化艺术品存在权利瑕疵、内容违法违规、或其他可能产生严重负面舆情等情形，导致数字文化艺术品被屏蔽或限制使用的，您同意我们无需承担相关责任，您可以自行联系发行方解决，或者您也可以联系泡泡计划官方协调处理。
      </div>
      <div class="userAgreement-bole">九、知识产权</div>
      <div class="userAgreement-text">
        9.1平台上的所有内容，包括文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由我们或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、作权、商业秘密等。
      </div>
      <div class="userAgreement-text">
        9.2非经我们或其他权利人书面同意，您不得擅自使用、修改反向编译、复制、公开传播、改变、散布、发行或公开发表转让、许可、商业利用平台和平台的相关软件、内容以及其他我们或权利人享有的知识产权，否则我们有权立即终止本协议，您需对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。
      </div>
      <div class="userAgreement-bole">十、其他</div>
      <div class="userAgreement-text">
        10.1为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。我们会以公告、客户端信息推送、发送系统消息等通知方式(统称“通知”)就协议的更新向您进行提示。除通知中另有说明外，更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您需立即停止使用相关服务。若您继续使用本协议项下相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束;双方协商一致，也可另行变更相关服务和对应条款内容。
      </div>
      <div class="userAgreement-text">
        10.2
        您理解并同意，出于本服务的业务特性，未经我们书面后意，您不得向第三方转让本协议项下的权利和义务。您进一步同意，在不对您使用本服务造成不利影响的前提下，我们可将在本协议项下的权利和义务全部或部分转让给我们的关联方而无需另行通知或征得您的同意。
      </div>
      <div class="userAgreement-text">
        10.3
        我们在平台页面展示的最新服务说明、平台规则等构成本协议不可分割的一部分。如果条款之间存在不一致之处，以
      </div>
      <div class="userAgreement-text">(1)服务说明、平台规则;</div>
      <div class="userAgreement-text">(2)本协议的先后顺序进行适用</div>
      <div class="userAgreement-text">
        10.4本协议的部分条款无论因何种原因部分无效或不可执行其余条款仍有效并对双方仍具有约束力。
      </div>
      <div class="userAgreement-text">
        10.5
        本协议于当地律师事务所。本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应提交本协议签订地人民法院管辖。
      </div>
      <div class="userAgreement-text">
        附件-《违规用户处罚规则》为维护数字文化艺术品平台的正常运营，保障各方的合法权益，严格防范和打击任何形式的转售、炒作数字文化艺术品的违法违规行为，依据各区块链用户服务协议制定本规则。
      </div>
      <div class="userAgreement-text">
        1.如果我们收到投诉或举报，或通过其他途径，经过合理判断认定您从事了以下违法、违规或违约行为的，我们有权根据您的具体行为和情节实施下述处罚措施，并向您发送处罚通知:行为具体情形刑罚措施
        违规使用数字文化艺术违规使用品、包括但不限于超过数字文化发行方或授权方的授权艺术品范围使用数字文化艺术R
        限制您对外分享数字文化艺术品，并可视具体情况限制您购买数字文化艺术品
        发布违规发布违规或不当信息，或不当信损害平台或区块链平台为或他人合法权益
        违法犯罪行为 构成违法犯罪的，包括但不限于欺诈、洗钱、非法经营等
        违反平台支持的区块链相应的用户服务协议、其他违约平台《购买、转赠、寄行为售须知》、平台规则的行为
        限制您对外分享数字文化艺术品，并可视具体情况1年内关闭您账户的数字转赠功能
        封禁账号，报警并且移交司法机关处理
        依据《泡泡计划用户协议》、《泡泡计划隐私协议》和平台规则酌情处理。
      </div>
      <div class="userAgreement-text">
        2.如您同时涉及前述多项违反情形的，我们有权分别采取多项对应的处罚措施，或有权按照从重处罚原则对您的相关行为予以处罚。
      </div>
      <div class="userAgreement-text">
        3.针对他人对您的投诉和举报，或我们对您的处罚措施，您可以提交相关证明材料进行申诉，我们将依据事实进行处理
      </div>
      <div class="userAgreement-text">
        4.您理解并确认，我们有权依据国家法律法规、监管政策、风险防范的需要对上述处罚规则进行修改、调整、补充和公示。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagess {
  width: 100%;
  background: #9a13a5;
  //   padding-top: 80px;
  position: relative;

  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 76px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
}
.userAgreement::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.userAgreement {
  width: 70%;
  margin: auto;
  padding-top: 80px;
  line-height: 40px;
  color: #fff;
  height: calc(100vh - 94px);
  overflow: auto;
  font-size: 20px;
  &-title {
    font-weight: 700;
  }
  &-text {
  }
  &-bole {
    font-weight: 900;
    margin: 10px 0;
  }
}
</style>